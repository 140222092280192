import { CONST } from '@/shared/utils/constants';
import { ApplicationUserPermissionsState } from './types';
import applicationUserPermissions from '@/shared/model/applicationUserPermissions';

export const state = (): ApplicationUserPermissionsState => initialState();

export const initialState = (): ApplicationUserPermissionsState => ({
  currentUserId: CONST.emptyGuid,
  currentPermissions: applicationUserPermissions.parse(),
  isLoading: false,
});