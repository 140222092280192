import { ActionTree } from 'vuex';
import { DirectDebitState } from './types';
import { i18nGlobal } from '@/i18n';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { RootState } from '../../types';
import { defaultBackendDirectDebit } from '@/shared/backend/directDebit';
import { DirectDebit } from '@/shared/model/directDebit';
import DownloadUtils from '@/shared/utils/downloadUtils';

const logger = new Logger('actions');
export const actions: ActionTree<DirectDebitState, RootState> = {
  async createPreview({ commit }, files: File[]) {
    const data = new FormData();
    files.forEach(file => {
      data.append('Files', file, file.name);
    });
    const lang = i18nGlobal.locale.value;

    return defaultBackendDirectDebit
      .createPreview(data, lang)
      .then((resp) => {
        commit('setDirectDebit', resp.data);
        return resp.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  createSEPAFile({ }, directDebitData: DirectDebit) {
    return defaultBackendDirectDebit
      .createSEPAFile(directDebitData)
      .then((response) => {
        let fileName = DownloadUtils.getFileName(
          response.headers['content-disposition']
        );
        const type = response.headers['content-type'];
        if (DownloadUtils.isIeOrEdge(window)) {
          const file = new File([response.data], fileName, {
            type: type,
          });
          (window.navigator as any).msSaveOrOpenBlob(file, fileName);
        } else {
          const blob = new Blob([response.data], { type: type });
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          window.URL.revokeObjectURL(url);
        }
      })
  }
}
