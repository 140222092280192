import { CostDiscount } from './costDiscount';
import DateUtils from '../utils/dateUtils';

export interface Reference {
  id?: string; // System.Guid
  name: string; // string
  info?: string;
  cost?: number; //decimal
  type?: CostTypeEnum;
  minAge?: number; //ubyte
  maxAge?: number; //ubyte
  active: boolean;
  isInUse: boolean;
  requiredDocuments: string;
  costOverrides?: { memberStatusName: string; cost: number }[];
  departmentId?: string; // System.Guid
  departmentName?: string;
  companyId?: string; // System.Guid
  createdDate: string; // System.DateTime
  creatorFullName: string;
  discounts: CostDiscount[];
  isAvailableForAge(age: number): boolean;
  getCurrentlyActiveDiscount(): CostDiscount | null;
  getCurrentCost(): number;
  getCurrentName(): string;
  isCurrentlyDiscounted(): boolean;
  getActiveDiscountAtDate(date: string): CostDiscount | null;
  getCostAtDate(date: string): number;
  getNameAtDate(date: string): string;
  isDiscountedAtDate(date: string): boolean;
}

export type CostTypeEnum = 0 | 1 | 2 | 3;
export const CostTypeEnum = {
  ForRegistration: 0 as CostTypeEnum,
  Empty: 1 as CostTypeEnum,
  MonthlyMembershipCost: 2 as CostTypeEnum,
  YearlyMembershipCost: 3 as CostTypeEnum,
};

export type ReferenceTable =
  | 'Skill'
  | 'MembershipType'
  | 'Characteristic'
  | 'Licence'
  | 'Cost'
  | 'Equipment'
  | 'MemberStatus'
  | 'AttendeeRole';
export const ReferenceTable = {
  Skill: 'Skill' as ReferenceTable,
  MembershipType: 'MembershipType' as ReferenceTable,
  Characteristic: 'Characteristic' as ReferenceTable,
  Licence: 'Licence' as ReferenceTable,
  Cost: 'Cost' as ReferenceTable,
  Equipment: 'Equipment' as ReferenceTable,
  MemberStatus: 'MemberStatus' as ReferenceTable,
  AttendeeRole: 'AttendeeRole' as ReferenceTable,
};

export interface Value {
  value: Reference[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Reference>): any {
  let result: any = {
    Id: data?.id || undefined,
    Name: data?.name || '',
    Info: data?.info ?? undefined,
    CompanyId: data?.companyId || undefined,
    Type: data?.type || CostTypeEnum.ForRegistration,
    Cost: data?.cost || undefined,
    MinAge: data?.minAge ?? undefined,
    MaxAge: data?.maxAge || undefined,
    Active: data?.active ?? true,
    RequiredDocuments: data?.requiredDocuments || undefined,
    DepartmentId: data?.departmentId || undefined,
  };
  return result;
}

function parse(data?: Partial<Reference>): Reference {
  let costTypeInput = data?.type ?? 'Empty';
  let costType: CostTypeEnum = CostTypeEnum.Empty;

  if (typeof costTypeInput == 'string')
    costType = CostTypeEnum[costTypeInput as keyof typeof CostTypeEnum];
  else costType = costTypeInput as CostTypeEnum;

  return {
    id: data?.id || undefined,
    name: data?.name || '',
    info: data?.info ?? undefined,
    companyId: data?.companyId || undefined,
    type: costType,
    createdDate: data?.createdDate || '',
    creatorFullName: data?.creatorFullName || '',
    minAge: data?.minAge ?? undefined,
    maxAge: data?.maxAge ?? undefined,
    active: data?.active ?? true,
    isInUse: data?.isInUse ?? false,
    requiredDocuments: data?.requiredDocuments || '',
    costOverrides: data?.costOverrides ?? [],
    departmentId: data?.departmentId ?? undefined,
    departmentName: data?.departmentName ?? undefined,
    cost: data?.cost ?? 0,
    discounts: data?.discounts ?? [],
    isAvailableForAge(age: number): boolean {
      return (
        age == -1 ||
        ((this.minAge == undefined || this.minAge <= age) &&
          (this.maxAge == undefined || this.maxAge >= age))
      );
    },
    getCurrentlyActiveDiscount(): CostDiscount | null {
      return this.getActiveDiscountAtDate(DateUtils.getTodayDateAsIsoString());
    },
    getCurrentCost(): number {
      let currentlyActiveDiscount = this.getCurrentlyActiveDiscount();
      return (currentlyActiveDiscount?.altCost ?? this.cost) || 0;
    },
    getCurrentName() {
      let currentlyActiveDiscount = this.getCurrentlyActiveDiscount();
      return currentlyActiveDiscount?.altName ?? this.name;
    },
    isCurrentlyDiscounted() {
      return this.getCurrentlyActiveDiscount() != null;
    },
    getActiveDiscountAtDate(date: string): CostDiscount | null {
      return (
        this.discounts.filter(
          (x) =>
            x.dateFrom.substring(0, 10) <= date &&
            x.dateTill.substring(0, 10) >= date
        )[0] ?? null
      );
    },
    getCostAtDate(date: string): number {
      let activeDiscount = this.getActiveDiscountAtDate(date);
      return (activeDiscount?.altCost ?? this.cost) || 0;
    },
    getNameAtDate(date: string): string {
      let activeDiscount = this.getActiveDiscountAtDate(date);
      return activeDiscount?.altName ?? this.name;
    },
    isDiscountedAtDate(date: string): boolean {
      return this.getActiveDiscountAtDate(date) != null;
    },
  };
}

export function getCostTypeText(costType: CostTypeEnum): string {
  return Object.keys(CostTypeEnum)[
    Object.values(CostTypeEnum).findIndex((entry) => entry == costType)
  ];
}

export default {
  parse,
  toAPI: toAPI,
};
