export interface Company {
  id?: string;
  name: string;
  street: string;
  postcode: string;
  city: string;
  countryId?: string;
  countryName: string;
  phone: string;
  supportEmail: string;
  email: string;
  emailAccounting: string;
  web: string;
  sector: string;
  iban: string;
  creditorIdentifier: string;
  localCourt: string;
  registeredOffice: string;
  managingDirector: string;
  inactivityMinutes: number;
  trialBeginDate?: string;
  stripeCustomerId: string;
}

export interface Value {
  value: Company;
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Company>): any {
  return {
    Id: data?.id || undefined,
    Name: data?.name || '',
    City: data?.city || '',
    CountryId: data?.countryId || undefined,
    Email: data?.email || '',
    EmailAccounting: data?.emailAccounting || '',
    InactivityMinutes: data?.inactivityMinutes ? Math.round(data.inactivityMinutes) : 0,
    LocalCourt: data?.localCourt || '',
    ManagingDirector: data?.managingDirector || '',
    Phone: data?.phone || '',
    Postcode: data?.postcode || '',
    RegisteredOffice: data?.registeredOffice || '',
    Sector: data?.sector || '',
    IBAN: data?.iban || '',
    CreditorIdentifier: data?.creditorIdentifier || '',
    Street: data?.street || '',
    SupportEmail: data?.supportEmail || '',
    Web: data?.web || '',
  };
}

function parse(data?: Partial<Company>): Company {
  return {
    id: data?.id || undefined,
    name: data?.name || '',
    city: data?.city || '',
    countryId: data?.countryId || undefined,
    countryName: data?.countryName || '',
    email: data?.email || '',
    emailAccounting: data?.emailAccounting || '',
    inactivityMinutes: data?.inactivityMinutes || 0,
    localCourt: data?.localCourt || '',
    managingDirector: data?.managingDirector || '',
    phone: data?.phone || '',
    postcode: data?.postcode || '',
    registeredOffice: data?.registeredOffice || '',
    sector: data?.sector || '',
    iban: data?.iban || '',
    creditorIdentifier: data?.creditorIdentifier || '',
    street: data?.street || '',
    supportEmail: data?.supportEmail || '',
    web: data?.web || '',
    trialBeginDate: data?.trialBeginDate || undefined,
    stripeCustomerId: data?.stripeCustomerId || ''
  };
}

export default {
  parse,
  toAPI: toAPI,
};
