import { MutationTree } from 'vuex';
import { DirectDebitState } from './types';
import directDebit, { DirectDebit } from '@/shared/model/directDebit';

export const mutations: MutationTree<DirectDebitState> = {
  setDirectDebit(state, payload?: DirectDebit) {
    state.directDebit =  directDebit.parse(payload);
  },
  clearDirectDebit(state) {
    state.directDebit = undefined;
  }
}