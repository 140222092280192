import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { DefaultBackendHelper } from '../utils/backendHelper';
import directDebitFuncs, { DirectDebit } from '../model/directDebit';

const logger = new Logger('backend.directDebit');

export interface BackendDirectDebit {
  createPreview(formData: FormData, lang?: string): AxiosPromise<DirectDebit>;
  createSEPAFile(directDebitData: DirectDebit): AxiosPromise<any>;
}

export const defaultBackendDirectDebit: BackendDirectDebit = {
  createPreview(formData: FormData, lang?: string): AxiosPromise<DirectDebit> {
    let url = `${URLS.directDebit}/Preview`;
    if (lang) DefaultBackendHelper.addParamsToUrl(url, [`lang=${lang}`]);

    return instance.post<DirectDebit>(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      transformRequest: (data) => data,
    });
  },
  createSEPAFile(directDebitData: DirectDebit): AxiosPromise<any> {
    const url = `${URLS.directDebit}/CreateSEPAFile`;
    return instance.post<any>(url, directDebitFuncs.toAPI(directDebitData), {
      responseType: 'blob',
    });
  }
};
