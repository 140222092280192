import { MutationTree } from 'vuex';
import { ApplicationUserPermissionsState } from './types';
import applicationUserPermissions, { ApplicationUserPermissions } from '@/shared/model/applicationUserPermissions';

export const mutations: MutationTree<ApplicationUserPermissionsState> = {
  setCurrentPermissions(state, payload: ApplicationUserPermissions) {
    state.currentPermissions = applicationUserPermissions.parse(payload);
  },
  setCurrentUserId(state, payload: string) {
    state.currentUserId = payload;
  },
  setPermissionsIsLoading(state, payload: boolean) {
    state.isLoading = payload;
  }
}