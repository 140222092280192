import { ActionTree } from 'vuex';
import { AuthState } from './types';
import { RootState } from '../../types';
import { defaultBackendAccount } from '../../../backend/account'; //TODO @/shared/backend/account
import { i18nGlobal } from '../../../../i18n';
import { Credentials } from '../../../model/credentials';
import store from '../..';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import GeneralUtils from '@/shared/utils/generalUtils';
import { defaultBackendReferenceParent } from '@/shared/backend/referenceParent';
import { ReferenceParentTable } from '@/shared/model/referenceParent';
import { AxiosResponse } from 'axios';
import { Value } from '@/shared/model/referenceParent';

const logger = new Logger('actions');
export const actions: ActionTree<AuthState, RootState> = {
  /**
   * Login to the backend.
   */
  async login({ commit, dispatch }, payload: Credentials) {
    // authenticate to get the access token
    return defaultBackendAccount
      .authenticate(payload.username!, payload.password!, payload.rememberMe)
      .then((response) => {
        commit('setToken', response.data);
        commit('setAccountId', response.data.userId);
        return response;
      })
      .catch((e) => {
        logger.error(e);
        throw e;
      });
  },

  async googleLogin({ commit, dispatch }, payload: string) {
    // authenticate to get the access token
    logger.log('googleLogin');
    return defaultBackendAccount
      .authenticateGoogle(payload)
      .then((response) => {
        commit('setToken', response.data);
        commit('setIsGoogleLogin', true);
        commit('setAccountId', response.data.userId);
        return response;
      })
      .catch((e) => {
        logger.error(e);
        throw e;
      });
  },

  async facebookLogin({ commit, dispatch }, payload: string) {
    // authenticate to get the access token
    logger.log('facebookLogin');
    return defaultBackendAccount
      .authenticateFacebook(payload)
      .then((response) => {
        commit('setToken', response.data);
        commit('setIsFacebookLogin', true);
        commit('setAccountId', response.data.userId);
        return response;
      })
      .catch((e) => {
        logger.error(e);
        throw e;
      });
  },

  async passwordForgotten({ commit, dispatch }, email: string) {
    try {
      const status = (await defaultBackendAccount.initResetPassword(email))
        .status;
    } catch (e) {
      commit(
        'setSnackbarError',
        {
          //@ts-ignore
          message: i18nGlobal.t(`pwd_forgotten.pwd_forgotten_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },
  async loadAccountDetails({ commit, getters }, id?: number) {
    return defaultBackendAccount
      .getMyAccount()
      .then((result) => {
        commit('setAccount', result.data.account);
        commit('setAccountId', result.data.account.id);
        commit('setCompanyId', result.data.company.id);
        commit('setSubscriptionInfo', result.data.company);
        commit('setPermissions', result.data.permissions);
        //success('auth.acc_load.success', commit)
      })
      .then(async () => {
        if (getters.isAttendee) {
          await defaultBackendReferenceParent
            .getAllReferenceParents(
              getters.getAccountId,
              ReferenceParentTable.MembershipTypeAttendee,
              undefined
            )
            .then((response: AxiosResponse<Value>) => {
              commit('setMembershipTypes', response.data);
            });
        } else {
          commit('setMembershipTypes', []);
        }
      })
      .catch((e) => {
        commit('setAccount', undefined);
      });
  },
  /**
   * Clears the access token, effectively logging out the user.
   */
  logout({ commit }) {
    return defaultBackendAccount.logout().then((result) => {
      commit('setAccount', undefined);
      commit('setAccountId', null);
      commit('clearTokens');
      store.dispatch('resetAllState');
      return result;
    });
  },
  createAdmin({ commit, dispatch }, payload: any) {
    return defaultBackendAccount
      .createAdmin(payload)
      .then((response) => {
        commit(
          'setSnackbarSuccess',
          {
            //@ts-ignore
            message: i18nGlobal.t(
              `createAdmin_management.success.company_created`
            ),
            duration: 5000,
          },
          { root: true }
        );
      })
      .catch((error) => {
        console.log(error);
        commit(
          'setSnackbarError',
          {
            message: i18nGlobal.t(
              `createAdmin_management.error.company_created`
            ),
            duration: 5000,
          },
          { root: true }
        );
      });
  },
  createContact({ commit, dispatch }, payload: any) {
    return defaultBackendAccount
      .createContact(payload)
      .then((response) => {
        commit(
          'setSnackbarSuccess',
          {
            message: i18nGlobal.t(
              `employee_management.success.create_employee`
            ),
            duration: 5000,
          },
          { root: true }
        );
      })
      .catch((error) => {
        console.log(error);
        commit(
          'setSnackbarError',
          {
            message: i18nGlobal.t(`employee_management.error.create_employee`),
            duration: 5000,
          },
          { root: true }
        );
      });
  },
  resendConfirmationEmail({ commit, dispatch }, id: number) {
    return defaultBackendAccount
      .resendConfirmationEmail(id)
      .then((response) => {
        commit(
          'setSnackbarSuccess',
          {
            //@ts-ignore
            message: i18nGlobal.t(
              `success.resend_confirmation_email`
            ),
            duration: 5000,
          },
          { root: true }
        );
      })
      .catch((error) => {
        console.log(error);
        commit(
          'setSnackbarError',
          {
            message: i18nGlobal.t(
              `error.resend_confirmation_email`
            ),
            duration: 5000,
          },
          { root: true }
        );
      });
  },

  async impersonateUser({ commit }, userId: string) {
    return defaultBackendAccount
      .impersonateUser(userId)
      .then((response) => {
        commit('setToken', response.data);
        commit('setAccountId', response.data.userId);
        return response;
      })
      .catch((e) => {
        logger.error(e);
      });
  },

  async isResponsibleOrDeputy({ commit, dispatch }) {
    try {
      const response = await defaultBackendAccount.isResponsibleOrDeputy();
      commit('setIsResponsibleOrDeputy', response.data);
    } catch (e) {
      console.log(e);
      commit('setIsResponsibleOrDeputy', {});
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },
  isUniqueEmail({ commit, dispatch }, payload: { email: string }) {
    return defaultBackendAccount
      .isUniqueEmail(payload.email)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        moduleUtils.error(
          i18nGlobal.t(`error.check_is_unique_email`),
          commit,
          e,
          logger
        );
        throw e;
      });
  },
  resetPasswordByEmail(
    { commit, dispatch },
    payload: {
      Email: string;
      UserName: string;
    }
  ) {
    return defaultBackendAccount
      .resetPasswordByEmail(payload)
      .then(() => {})
      .catch((e) => {
        // for security reasons (leak user emails) don't show any info except errors for developer
        if (GeneralUtils.isDevEnvironment()) {
          logger.debug('resetPasswordByEmail :>> ');
          logger.error(e);
        }
      });
  },
  setLanguage({ commit }, lang: string) {
    return defaultBackendAccount.setLanguage(lang).catch((e) => {
      moduleUtils.error('error', commit, e, logger);
      throw e;
    });
  },
};
