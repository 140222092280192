import moment, { Moment } from 'moment';
import {
  TimeOfDay,
  TimeOfDayLastHour,
  TypeOfPeriod1,
  TypeOfPeriod2,
} from '../model/periodTypes';

export default class DateUtils {
  public static getTimeOfDayString(time: string, t: Function): string {
    let timeOfDay = this.getTimeOfDay(time);
    return t(`time_of_day.${timeOfDay}`);
  }
  public static getStartEndPeriodByTypeOfDate(
    date: Moment,
    type: TypeOfPeriod1,
    locale: string = 'de'
  ) {
    const typeString =
      type == TypeOfPeriod1.Day
        ? 'day'
        : type == TypeOfPeriod1.Week
        ? 'week'
        : 'month';
    const start = moment(date)
      .locale(locale)
      .startOf(typeString as moment.unitOfTime.StartOf)
      .toISOString();

    const end = moment(date)
      .locale(locale)
      .endOf(typeString as moment.unitOfTime.StartOf)
      .toISOString();
    return {
      start,
      end,
    };
  }
  public static getPeriodString(
    period: TypeOfPeriod2,
    periodValues: number[],
    t: Function,
    appendPeriodType: boolean = true
  ): string {
    let periodTranslated =
      period == TypeOfPeriod2.Day
        ? t('app.period_day')
        : period == TypeOfPeriod2.Week
        ? t('app.period_week')
        : t('app.period_month');
    if (periodValues.length == 0 || period == TypeOfPeriod2.Day) {
      return periodTranslated;
    }
    let periodValuesTranslated = '';
    periodValues.forEach((value: number) => {
      periodValuesTranslated +=
        period == TypeOfPeriod2.Week
          ? t(`app.day_of_week_short[${value}]`) + ','
          : t(`app.month_short[${value}]`) + ',';
    });
    // remove last ','
    periodValuesTranslated = periodValuesTranslated.slice(0, -1);
    let result = appendPeriodType
      ? periodValuesTranslated + ` (${periodTranslated})`
      : periodValuesTranslated;
    return result;
  }

  static parseDateFromBackend(date: any, fallbackValue: string = '') {
    if (date === undefined || date === null || date === '') {
      return fallbackValue;
    }
    let substrDate = date.slice(0, 10);
    if (substrDate === '0001-01-01' || substrDate == undefined) {
      return fallbackValue;
    }
    return substrDate as string;
  }
  static dateNullableToApi(date: any) {
    if (date === undefined || date === '') {
      return null;
    }
    return date;
  }
  static isSameDate = (date1: any, date2: any): boolean => {
    if (!date1 || !date2) return false;
    const x1 = new Date(date1);
    const x2 = new Date(date2);
    return (
      x1.getDate() == x2.getDate() &&
      x1.getMonth() == x2.getMonth() &&
      x1.getFullYear() == x2.getFullYear()
    );
  };
  // TODO: (EGRUP-133) [HIGH] Handle 'dd.mm.yyyy' format
  public static formatDateTimeISO = (date: any): string | undefined => {
    if (!date) return undefined;

    const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    const localISOTime = new Date(date)
      .toISOString()
      .slice(0, -5)
      .replace('T', ' '); // => '2020-11-10 21:45:24'
    return localISOTime;
  };
  // TODO: (EGRUP-133) [HIGH] Handle 'dd.mm.yyyy' format
  public static formatDateISO = (date: any): string | undefined => {
    if (!date) return undefined;

    const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    const localISOTime = new Date(date).toISOString().slice(0, -14); //'2021-01-13T16:05:35.519185Z' => '2020-11-10'
    return localISOTime;
  };

  //#region Methods that used in `date-field` component
  public static isIsoStart(date: string) {
    let isoBeginYYYY = /\d{4}-/;
    return isoBeginYYYY.test(date);
  }

  /**
   * Convert default JS format `MM.DD.YYYY` to `DD.MM.YYYY`
   * @param date (date value)
   */
  public static switchMonthAndDay(date: string | Date): Date {
    if (this.isIsoStart(date.toString())) {
      // check ISO date format from datePicker (YYYY-MM-DD)
      return new Date(date);
    }

    // Default JS format is MM/DD/YYYY
    let dateParts = date.toString().split(/[.\-/]/);
    let year = +dateParts[2];
    let month = +dateParts[1];
    let day = +dateParts[0];

    // to avoid get `01.01.1921` from `1.1.21`
    if (year < 50) {
      year += 2000;
    }
    // month is 0-based, that's why we need dataParts[1] - 1
    var dateValue = new Date(year, month - 1, day);
    return dateValue;
  }

  // (EGRUP-156) when enter `1-1-22` in `date-field` input which should be `01.01.2022` in DatePicker, then we get `31.12.2021 21:00` for our timezone `+3` without ignoring Offset
  public static formatDateIsoNoOffset = (date: any): string | undefined => {
    if (!date) return undefined;

    date = DateUtils.switchMonthAndDay(date);
    const dateObj = new Date(date);

    // needs such logic with many `+` and  `slice(-2)` to handle correctly both 1 and 2 digit months and days
    // like `1-1-21` will become `2021-01-01`, but without `slice(-2)` or another additional logic from `11-12-21` we will get `2021-012-011` which return ERROR when try to open for datepicker
    const resultDate =
      dateObj.getFullYear() +
      '-' +
      ('0' + (dateObj.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + dateObj.getDate()).slice(-2);
    return resultDate;
  };

  /// convert `DD.MM.YYYY` to `YYYY-MM-DD`
  public static dotDateStringToISO(date: string): string {
    let dateParts = date.toString().split(/[.]/);
    let year = +dateParts[2];
    let month = +dateParts[1];
    let day = +dateParts[0];

    let isoDate = `${year}-${month}-${day}`;
    return isoDate;
  }

  /**
   * Convert ISO `YYYY-MM-DD` date format to `DD.MM.YYYY`
   * @param date
   */
  public static isoDateStringToDots(date: string): string {
    let dateParts = date.toString().split(/[-]/);
    let year = dateParts[0];
    let month = dateParts[1];
    let day = dateParts[2];

    let isoDate = `${day}.${month}.${year}`;
    return isoDate;
  }
  //#endregion

  public static getWeekDayNameOfDate(date: any, t: Function): string {
    let dateObj = new Date(date);
    let weekday = dateObj.getDay();

    return t(`app.day_of_week_short[${weekday}]`);
  }

  /**
   * Formats the date, preceded with the name of the respective weekday
   * @param date
   * @param locale
   * @param yearAbbreviated
   * @returns
   */
  public static formatDateWithWeekdayName(
    date: any,
    t: Function,
    locale: string = 'de',
    yearAbbreviated: boolean = false
  ): string {
    return `${this.getWeekDayNameOfDate(date, t)} ${this.formatDateWithLocale(
      date,
      locale,
      yearAbbreviated
    )}`;
  }

  /**
   * Format date (most likely ISO) to `DD.MM.YYYY` (DE) or `DD/MM/YYYY`(EN)
   * @param date
   * @param locale (portal locale [now `de` or `en`])
   * @param yearAbbreviated
   */
  public static formatDateWithLocale = (
    date: any,
    locale: string = 'de',
    yearAbbreviated: boolean = false
  ): string | undefined => {
    if (!date) return undefined;

    const time = date.toString().split(' ')[1]; // orginal time from the backend (no offsets)
    const dateOnly = DateUtils.formatDateIsoNoOffset(date);
    let resultDate = DateUtils.isoDateStringToDots(dateOnly!);

    if (yearAbbreviated) {
      resultDate =
        resultDate.substring(0, resultDate.length - 4) +
        resultDate.substring(resultDate.length - 2);
    }

    if (locale == 'en') {
      resultDate = (resultDate as any).replaceAll('.', '/');
    }
    // date + time (without offset)
    return time ? `${resultDate} ${time}` : resultDate;
  };

  public static formatDateTimeWithLocale = (
    date: any,
    locale: string = 'de'
  ): string | undefined => {
    if (date) {
      if (moment(String(date)).format('DD/MM/YYYY') == '01/01/0001') {
        return '-';
      }
      if (locale == 'de')
        return moment.utc(String(date)).format('DD.MM.YYYY HH:mm');
      else return moment.utc(String(date)).format('YYYY/MM/DD HH:mm');
    }
  };

  public static formatDateTimeWithLocaleAndTimezone(
    date: any,
    locale: string = 'de'
  ): string | undefined {
    return DateUtils.getLocalizedDateString(String(date), locale);
  }

  public static formatDayMonthWithLocale = (
    date: any,
    locale: string = 'de'
  ): string | undefined => {
    if (date) {
      if (moment(String(date)).format('DD/MM/YYYY') == '01/01/0001') {
        return '-';
      }
      if (locale == 'de')
        return moment(String(date)).locale(locale).format('DD.MM.');
      else return moment(String(date)).locale(locale).format('MM/DD');
    }
  };

  public static formaTimeFromBackendTimespan = (
    time: any
  ): string | undefined => {
    if (!time) return undefined;
    let parseTimeSpan = time
      .toString()
      .replace('PT', '')
      .replace('H', ':')
      .replace('M', '');
    let parseTimeSpanNoColon = parseTimeSpan.replace(':', '');
    // only Hours presented in the field like `16` or `9`
    if (parseTimeSpanNoColon.length <= 2) {
      parseTimeSpan = `${parseTimeSpanNoColon}:00`;
    }
    return parseTimeSpan;
  };

  public static toDateString = (date: any): string | undefined => {
    if (!date) return '-';

    const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    const localISOTime = new Date(date - tzoffset); // => '2020-11-10 21:45:24'
    return (
      localISOTime.getMonth() +
      '/' +
      localISOTime.getDate() +
      '/' +
      localISOTime.getFullYear()
    );
  };

  public static toTimespanString(time: string): string {
    if (time.match(/^\d\d:\d\d:\d\d$/) != null) {
      return time;
    }

    if (time.match(/^\d\d:\d\d$/) != null) {
      return `${time}:00`;
    }

    throw Error('Time string does not match the required pattern.');
  }

  public static getDateTimeByMinutesOffsetFromNow(minutesOffset: number): Date {
    return DateUtils.getDateTimeByMinutesOffsetFromDate(
      new Date(),
      minutesOffset
    );
  }

  public static getDateTimeByMinutesOffsetFromDate(
    inputDate: Date,
    minutesOffset: number
  ): Date {
    let dateTime = new Date(inputDate);
    dateTime.setMilliseconds(
      dateTime.getMilliseconds() + minutesOffset * 60000
    );

    return dateTime;
  }

  public static getCurrentLocalDateAsIsoString(
    simpleFormat: boolean = true
  ): string {
    let timezoneOffset = moment().utcOffset();
    let dateTime = moment.utc().add(timezoneOffset, 'minutes');

    if (simpleFormat) return dateTime.format('YYYY-MM-DD HH:mm');
    return dateTime.toISOString();
  }

  public static getCurrentLocalTimeAsString(): string {
    let timezoneOffset = moment().utcOffset();
    let dateTime = moment.utc().add(timezoneOffset, 'minutes');
    return dateTime.format('HH:mm');
  }

  public static getTodayDateAsIsoString(): string {
    return new Date().toISOString().substring(0, 10);
  }

  public static getLocalizedDateString(
    utcDate?: string,
    locale?: string,
    ignoreTimezone?: boolean,
    onlyDate?: boolean
  ): string {
    if (!utcDate) return '-';
    //full format date with timezone
    //2023-04-20T17:53:29.571813+03:00
    //we need to cut timezone part
    if (utcDate.length > 19) {
      utcDate = utcDate.slice(0, 19);
    }

    let timezoneOffset = moment().utcOffset();

    if (locale && locale === 'de')
      return moment(utcDate)
        .add(ignoreTimezone ? 0 : timezoneOffset, 'minutes')
        .format(onlyDate ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm');
    return moment(utcDate)
      .add(ignoreTimezone ? 0 : timezoneOffset, 'minutes')
      .format(onlyDate ? 'YYYY/MM/DD' : 'YYYY/MM/DD HH:mm');
  }

  // 'YYYY-MM-DD' -> Date
  public static dateFromIso = (date: string): Date => {
    let parts = date.split('-');
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    let result = new Date(+parts[0], +parts[1] - 1, +parts[2]);
    return result;
  };
  // Date without time part
  public static getNowDate = (utc: boolean = false): Date => {
    let tempDate = new Date(Date.now());

    if (utc) {
      return new Date(
        Date.UTC(
          tempDate.getUTCFullYear(),
          tempDate.getUTCMonth(),
          tempDate.getUTCDate()
        )
      );
    }

    return new Date(
      tempDate.getUTCFullYear(),
      tempDate.getUTCMonth(),
      tempDate.getUTCDate()
    );
  };

  /**
   * Convert date from `DD.MM.YYYY` to `YYYY/MM/DD`
   * @param date
   */
  public static dateStringDotsToSlash(date: string): string {
    if (moment(date, 'DD.MM.YYYY', true).isValid()) {
      date = moment(date, 'DD.MM.YYYY').format('YYYY/MM/DD');
    }
    return date;
  }

  public static getHourFromTimeString(time: string): number {
    if (time.search(/^\d\d:\d\d$/) === -1) {
      throw new Error(
        'The passed time string does not match the required pattern.'
      );
    }

    let hour = time.substring(0, 2);
    return parseInt(hour);
  }

  public static getTimeOfDay(time: string): string {
    let hour = this.getHourFromTimeString(time);

    if (hour <= TimeOfDayLastHour.Antemeridian) {
      return TimeOfDay.Antemeridian;
    }

    if (hour <= TimeOfDayLastHour.Noon) {
      return TimeOfDay.Noon;
    }

    if (hour <= TimeOfDayLastHour.Afternoon) {
      return TimeOfDay.Afternoon;
    }

    return TimeOfDay.Evening;
  }

  public static getHoursString(timeFrom: string, timeTill: string, locale: string): string {
    const totalMinutesDiff =
        this.getTotalMinutes(timeTill) -
        this.getTotalMinutes(timeFrom);

      return new Intl.NumberFormat(locale, {
        maximumFractionDigits: 2,
      }).format(totalMinutesDiff / 60);
  }

  public static getTotalMinutes(time: string): number {
    const hours =
      Number.parseInt(time[0]) * 10 + Number.parseInt(time[1]);
    const minutes =
      Number.parseInt(time[3]) * 10 + Number.parseInt(time[4]);

    return hours * 60 + minutes;
  }

  public static getAgeOfPerson(person: { birthday: string }): number {
    let birthDateString = person.birthday;

    if (!birthDateString) return -1;
    let nowDate = new Date();
    let birthDate = new Date(this.dateStringDotsToSlash(birthDateString));

    let yearDiff = nowDate.getFullYear() - birthDate.getFullYear();
    if (nowDate.getMonth() < birthDate.getMonth()) {
      yearDiff--;
    } else if (nowDate.getMonth() == birthDate.getMonth()) {
      if (nowDate.getDate() < birthDate.getDate()) {
        yearDiff--;
      }
    }

    return yearDiff;
  }

  public static getMonthsLeftInCurrentQuarter(): number {
    return 3 - (moment.utc().month() % 3);
  }

  public static getMonthsLeftInQuarter(date: string): number {
    return 3 - (moment.utc(date).month() % 3);
  }

  public static getMonthsLeftInCurrentQuarterString(t: Function): string {
    return this.getMonthsLeftInQuarterString(
      DateUtils.getTodayDateAsIsoString(),
      t
    );
  }

  public static getMonthsLeftInQuarterString(
    date: string,
    t: Function
  ): string {
    let firstMonth = moment.utc(date).month();
    let lastMonth = firstMonth + DateUtils.getMonthsLeftInQuarter(date) - 1;

    let values: number[] = [];
    for (let month = firstMonth; month <= lastMonth; month++)
      values.push(month);

    return DateUtils.getPeriodString(TypeOfPeriod2.Month, values, t, false);
  }
}
