export enum ROUTES {
  public_view = 'public-view',
  //Used in `$router.push()`
  login = 'login',
  login_attendee = 'login',

  register_attendee = 'register-attendee',
  my_profile = 'my-profile',
  my_company = 'my-company',
  my_invoices = 'my-invoices',

  courses = 'courses',
  course = 'course',

  locations = 'locations',
  location = 'location',

  print_qr_code = 'print_qr_code',
  print_attendance_list = 'print_attendance_list',

  attendances = 'attendances',

  trainers = 'trainers',
  trainer = 'trainer',

  attendees = 'attendees',
  attendees_interested = 'attendees-interested',
  attendee = 'attendee',
  attendee_interested = 'attendee-interested',

  employees = 'employees',
  employee = 'employee',

  invoices = 'invoices',

  invoice_items = 'invoice_items',

  new_password = 'new_password',

  attendee_registration = 'attendee_registration',

  attendee_registration_page = 'attendee_registration_page',

  attendee_registration_confirmation_page = 'attendee_registration_confirmation',

  attendee_registration_to_appointment = 'attendee_registration_to_appointment',

  checkIn_scanner = 'checkIn_scanner',

  error_view = 'error-view',

  reference_tables = 'reference_tables',

  self_checkin = 'self-checkin',

  settings = 'settings',

  attendee_presences = 'attendee_presences',

  attendances_presences = 'presences',

  courses_coursetimes = 'course-times',
  courses_appointments = 'appointments',
  event_appointment = 'event-appointment',

  conflicts = 'conflicts',

  tasks_requests = 'tasks_requests',

  tasks_interested_requests = 'interested',
  tasks_membership_requests = 'membership_requests',

  cancel_appointment = 'cancel-appointment',

  my_documents = 'my-documents',
  my_membership_subscriptions = 'my-membership-subscriptions',

  company_subscriptions = 'company-subscriptions',

  statistics = 'statistics',

  direct_debit_creation = 'direct-debit-creation',
}
