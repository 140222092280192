import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import attendee, { Attendee, Value } from '../model/attendee';
import ODataFilterBuilder from 'odata-filter-builder';
import { i18nGlobal } from '@/i18n';
import { ApplicationUser } from '../model/applicationUser';
import { isEmptyId } from '../utils/generalUtils';
import { CONST } from '../utils/constants';

const logger = new Logger('backend.Attendee');
export interface BackendAttendee {
  getAttendee(id: string): AxiosPromise<Attendee>;
  getAttendeeByAttendeeId(attendeeId: string): AxiosPromise<Attendee>;

  getAttendees: (
    searchParams: SearchParams,
    locationId?: string,
    departmentId?: string,
    active?: boolean,
    excludeAttendeeIds?: string[],
    attendeeIds?: string[],
  ) => AxiosPromise<Value>;
  getInterested: (
    searchParams: SearchParams,
    locationId?: string,
    departmentId?: string
  ) => AxiosPromise<Value>;
  getAttendeeWaitingList: (
    searchParams: SearchParams,
    appointmentId: string
  ) => AxiosPromise<Value>;
  getAttendeesByTrainerId: (
    searchParams: SearchParams,
    trainerId: string,
    locationId?: string,
    departmentId?: string
  ) => AxiosPromise<Value>;

  deleteAttendee(id: string): AxiosPromise;
  deleteInterestedAttendee(id: string): AxiosPromise;

  updateAttendee(
    Attendee: Attendee,
    sendMembershipTerminatedMessages?: boolean
  ): AxiosPromise<any>;
  updateInterestedAttendee(Attendee: Attendee): AxiosPromise<any>;

  registerAttendee(
    Attendee: ApplicationUser,
    locationId: string
  ): AxiosPromise<any>;
  registerInterestedAttendeeByEmployee(
    Attendee: Attendee,
    locationId: string
  ): AxiosPromise<any>;

  getLocationInfoById(id: string): AxiosPromise<Location>;
  requestNewMemberNumber(): AxiosPromise<string>;
}

export const defaultBackendAttendee: BackendAttendee = {
  getAttendeeWaitingList(
    searchParams: SearchParams,
    appointmentId: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder("and");
    const baseUrl = `${URLS.attendeeOdata}/GetWaitingList(appointmentId=${appointmentId})`;
    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAttendeeWaitingList${url}`);
    return instance.get<Value>(url);
  },

  getAttendee(id: string): AxiosPromise<Attendee> {
    let url = `${URLS.attendeeOdata}/${id}`;
    return instance.get<Attendee>(url);
  },

  getAttendeeByAttendeeId(attendeeId: string): AxiosPromise<Attendee> {
    let url = `${URLS.attendee}/GetSingleByAttendeeId/${attendeeId}`;
    return instance.get<Attendee>(url);
  },

  getAttendees(
    searchParams: SearchParams,
    locationId?: string,
    departmentId?: string,
    active?: boolean,
    excludeAttendeeIds?: string[],
    attendeeIds?: string[]
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder("and");

    odfb.ne(CONST.ApplicationUserId, null);
    if (active !== undefined) {
      odfb.eq(CONST.Active, active);
    }

    if (excludeAttendeeIds !== undefined) {
      excludeAttendeeIds.forEach((x) => odfb.ne(CONST.AttendeeId, x, false));
    }

    if (attendeeIds !== undefined) {
      let odfbInner = ODataFilterBuilder("or");
      attendeeIds.forEach((x) => odfbInner.eq(CONST.AttendeeId, x, false));
      odfb.and(odfbInner);
    }

    let baseUrl;
    if (departmentId != undefined) {
      baseUrl = `${URLS.attendeeOdata}/GetInDepartment(departmentId=${departmentId},trainerId=null)`;
    } else if (locationId != undefined) {
      baseUrl = `${URLS.attendeeOdata}/GetInLocation(locationId=${locationId},trainerId=null)`;
    } else {
      baseUrl = `${URLS.attendeeOdata}`;
    }

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAttendees${url}`);
    return instance.get<Value>(url);
  },
  getInterested(
    searchParams: SearchParams,
    locationId?: string,
    departmentId?: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder("and");
    odfb.eq(CONST.ApplicationUserId, null);
    let baseUrl;
    if (departmentId != undefined) {
      baseUrl = `${URLS.attendeeOdata}/GetInterestedInDepartment(departmentId=${departmentId})`;
    } else if (locationId != undefined) {
      baseUrl = `${URLS.attendeeOdata}/GetInterestedInLocation(locationId=${locationId})`;
    } else {
      baseUrl = `${URLS.attendeeOdata}`;
    }

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getInterested${url}`);
    return instance.get<Value>(url);
  },
  getAttendeesByTrainerId(
    searchParams: SearchParams,
    trainerId: string,
    locationId?: string,
    departmentId?: string
  ) {
    var odfb = ODataFilterBuilder("and");

    let baseUrl;
    if (departmentId != undefined) {
      baseUrl = `${URLS.attendeeOdata}/GetInDepartment(departmentId=${departmentId},trainerId=${trainerId})`;
    } else if (locationId != undefined) {
      baseUrl = `${URLS.attendeeOdata}/GetInLocation(locationId=${locationId},trainerId=${trainerId})`;
    } else {
      baseUrl = `${URLS.attendeeOdata}/GetByTrainerId(trainerId=${trainerId})`;
    }

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getByTrainerId${url}`);
    return instance.get<Value>(url);
  },

  deleteAttendee(id: string): AxiosPromise {
    logger.debug("deleteAttendee");
    return instance.delete(`${URLS.attendee}/${id}`);
  },
  deleteInterestedAttendee(id: string): AxiosPromise {
    logger.debug("deleteInterestedAttendee");
    return instance.delete(`${URLS.attendee}/DeleteInterested/${id}`);
  },

  updateAttendee(
    Attendee: Attendee,
    sendMembershipTerminatedMessages?: boolean
  ): AxiosPromise<any> {
    logger.debug("updateAttendee");
    if (!isEmptyId(Attendee.id)) {
      const payload = attendee.toAPI(Attendee);
      payload.SendMembershipTerminatedMessages =
        sendMembershipTerminatedMessages ?? false;

      return instance.put<Attendee>(
        `${URLS.attendee}/${i18nGlobal.locale.value}`,
        payload
      );
    } else {
      return instance.post<Attendee>(
        `${URLS.attendee}/${i18nGlobal.locale.value}`,
        attendee.toAPI(Attendee)
      );
    }
  },
  updateInterestedAttendee(Attendee: Attendee): AxiosPromise<any> {
    logger.debug(`${URLS.attendee}/UpdateInterested/${Attendee.attendeeId}`);
    return instance.put<Attendee>(
      `${URLS.attendee}/UpdateInterested/${Attendee.attendeeId}`,
      attendee.toAPI(Attendee)
    );
  },
  registerAttendee(
    Attendee: ApplicationUser,
    locationId: string
  ): AxiosPromise<any> {
    logger.debug("updateAttendee");
    return instance.post<Attendee>(
      `${URLS.account}/RegisterAttendee/${i18nGlobal.locale.value}/${locationId}`,
      {
        FirstName: Attendee.firstName,
        LastName: Attendee.lastName,
        Email: Attendee.email,
        UserName: Attendee.userName,
        Password: Attendee.password,
      }
    );
  },
  registerInterestedAttendeeByEmployee(
    Attendee: Attendee,
    locationId: string
  ): AxiosPromise<any> {
    logger.debug(`${URLS.attendee}/AddInterested/${locationId}`);
    return instance.post<Attendee>(
      `${URLS.attendee}/AddInterested/${locationId}`,
      attendee.toAPI(Attendee)
    );
  },
  getLocationInfoById(id: string): AxiosPromise<Location> {
    let url = `${URLS.attendeeRegisterByQrCode}/GetLocationInfoById/${id}`;
    return instance.get<Location>(url);
  },
  requestNewMemberNumber(): AxiosPromise<string> {
    let url = `${URLS.attendee}/GetNextMemberNumber`;
    return instance.get<string>(url);
  },
};
