import applicationUserPermissions from '@/shared/model/applicationUserPermissions';
import { AuthState } from './types';

export const state = (): AuthState => initialState();

export const initialState = (): AuthState => ({
  error: false,
  account: null,
  accountId: undefined,
  companyId: undefined,
  membershipTypes: [],
  permissions: applicationUserPermissions.parse(),
  isAdmin: false,
  isManager: false,
  isEmployee: false,
  isDepartmentLeader: false,
  isTrainer: false,
  isAttendee: false,
  isFacebookLogin: false,
  isGoogleLogin: false,
  totalActiveUsersInDb: 0,
  totalLicences: 0,
  isResponsibleOrDeputy: false,
  trialBeginDate: undefined,
  trialDaysLeft: -1,
});
