import { AUTHORITIES } from "../store/modules/auth";
import { CONST } from "../utils/constants";

export interface ApplicationUserPermissions {
  id: string;
  applicationUserId: string;
  companyId: string;

  homeConflictsViewEdit: boolean;
  homeTodoViewEdit: boolean;
  homeNewsView: boolean;
  homeNewsCreateDelete: boolean;
  homeNewsEdit: boolean;
  homeReportsView: boolean;
  homeCalendarEdit: boolean;

  locationsView: boolean;
  locationsCreateDelete: boolean;
  locationsEdit: boolean;

  employeesView: boolean;
  employeesCreateDelete: boolean;
  employeesEdit: boolean;

  trainersView: boolean;
  trainersCreateDelete: boolean;
  trainersEdit: boolean;

  coursesView: boolean;
  coursesCreateDelete: boolean;
  coursesEdit: boolean;
  dateTimesView: boolean;
  dateTimesEdit: boolean;
  appointmentsView: boolean;
  appointmentsCreateDelete: boolean;
  appointmentsEdit: boolean;

  invoicesView: boolean;
  invoicesEdit: boolean;
  invoicePositionsView: boolean;
  invoicePositionsEdit: boolean;
  directDebitViewCreate: boolean;

  attendeesView: boolean;
  attendeesCreateDelete: boolean;
  attendeesEdit: boolean;
  interestedView: boolean;
  interestedCreateDelete: boolean;
  interestedEdit: boolean;

  statisticsViewCreate: boolean;
  myCompanyViewEdit: boolean;
  companySubscriptionsViewEdit: boolean;
  settingsViewEdit: boolean;

  referencesView: boolean;
  tariffsCreateDelete: boolean;
  tariffsEdit: boolean;
  departmentsCreateDelete: boolean;
  departmentsEdit: boolean;
  membershipTypesCreateDelete: boolean;
  membershipTypesEdit: boolean;
  memberStatusesCreateDelete: boolean;
  memberStatusesEdit: boolean;
  costsCreateDelete: boolean;
  costsEdit: boolean;
  licencesCreateDelete: boolean;
  licencesEdit: boolean;
  skillsCreateDelete: boolean;
  skillsEdit: boolean;
  characteristicsCreateDelete: boolean;
  characteristicsEdit: boolean;
  equipmentsCreateDelete: boolean;
  equipmentsEdit: boolean;
  attendeeRolesEdit: boolean;
  attendeeRolesCreateDelete: boolean;
}

function toAPI(data: Partial<ApplicationUserPermissions>): any {
  return {
    Id: data?.id || CONST.emptyGuid,
    ApplicationUserId: data?.applicationUserId || CONST.emptyGuid,
    CompanyId: data?.companyId || CONST.emptyGuid,

    HomeConflictsViewEdit: data?.homeConflictsViewEdit ?? false,
    HomeTodoViewEdit: data?.homeTodoViewEdit ?? false,
    HomeNewsView: data?.homeNewsView ?? false,
    HomeNewsCreateDelete: data?.homeNewsCreateDelete ?? false,
    HomeNewsEdit: data?.homeNewsEdit ?? false,
    HomeReportsView: data?.homeReportsView ?? false,
    HomeCalendarEdit: data?.homeCalendarEdit ?? false,

    LocationsView: data?.locationsView ?? false,
    LocationsCreateDelete: data?.locationsCreateDelete ?? false,
    LocationsEdit: data?.locationsEdit ?? false,

    EmployeesView: data?.employeesView ?? false,
    EmployeesCreateDelete: data?.employeesCreateDelete ?? false,
    EmployeesEdit: data?.employeesEdit ?? false,

    TrainersView: data?.trainersView ?? false,
    TrainersCreateDelete: data?.trainersCreateDelete ?? false,
    TrainersEdit: data?.trainersEdit ?? false,

    CoursesView: data?.coursesView ?? false,
    CoursesCreateDelete: data?.coursesCreateDelete ?? false,
    CoursesEdit: data?.coursesEdit ?? false,
    DateTimesView: data?.dateTimesView ?? false,
    DateTimesEdit: data?.dateTimesEdit ?? false,
    AppointmentsView: data?.appointmentsView ?? false,
    AppointmentsCreateDelete: data?.appointmentsCreateDelete ?? false,
    AppointmentsEdit: data?.appointmentsEdit ?? false,

    InvoicesView: data?.invoicesView ?? false,
    InvoicesEdit: data?.invoicesEdit ?? false,
    InvoicePositionsView: data?.invoicePositionsView ?? false,
    InvoicePositionsEdit: data?.invoicePositionsEdit ?? false,
    DirectDebitViewCreate: data?.directDebitViewCreate ?? false,

    AttendeesView: data?.attendeesView ?? false,
    AttendeesCreateDelete: data?.attendeesCreateDelete ?? false,
    AttendeesEdit: data?.attendeesEdit ?? false,
    InterestedView: data?.interestedView ?? false,
    InterestedCreateDelete: data?.interestedCreateDelete ?? false,
    InterestedEdit: data?.interestedEdit ?? false,

    StatisticsViewCreate: data?.statisticsViewCreate ?? false,
    MyCompanyViewEdit: data?.myCompanyViewEdit ?? false,
    CompanySubscriptionsViewEdit: data?.companySubscriptionsViewEdit ?? false,
    SettingsViewEdit: data?.settingsViewEdit ?? false,

    ReferencesView: data?.referencesView ?? false,
    TariffsCreateDelete: data?.tariffsCreateDelete ?? false,
    TariffsEdit: data?.tariffsEdit ?? false,
    DepartmentsCreateDelete: data?.departmentsCreateDelete ?? false,
    DepartmentsEdit: data?.departmentsEdit ?? false,
    MembershipTypesCreateDelete: data?.membershipTypesCreateDelete ?? false,
    MembershipTypesEdit: data?.membershipTypesEdit ?? false,
    MemberStatusesCreateDelete: data?.memberStatusesCreateDelete ?? false,
    MemberStatusesEdit: data?.memberStatusesEdit ?? false,
    CostsCreateDelete: data?.costsCreateDelete ?? false,
    CostsEdit: data?.costsEdit ?? false,
    LicencesCreateDelete: data?.licencesCreateDelete ?? false,
    LicencesEdit: data?.licencesEdit ?? false,
    SkillsCreateDelete: data?.skillsCreateDelete ?? false,
    SkillsEdit: data?.skillsEdit ?? false,
    CharacteristicsCreateDelete: data?.characteristicsCreateDelete ?? false,
    CharacteristicsEdit: data?.characteristicsEdit ?? false,
    EquipmentsCreateDelete: data?.equipmentsCreateDelete ?? false,
    EquipmentsEdit: data?.equipmentsEdit ?? false,
    AttendeeRolesEdit: data?.attendeeRolesEdit ?? false,
    AttendeeRolesCreateDelete: data?.attendeeRolesCreateDelete ?? false,
  };
}

function parse(data?: Partial<ApplicationUserPermissions>): ApplicationUserPermissions {
  return {
    id: data?.id || CONST.emptyGuid,
    applicationUserId: data?.applicationUserId || CONST.emptyGuid,
    companyId: data?.companyId || CONST.emptyGuid,

    homeConflictsViewEdit: data?.homeConflictsViewEdit ?? false,
    homeTodoViewEdit: data?.homeTodoViewEdit ?? false,
    homeNewsView: data?.homeNewsView ?? false,
    homeNewsCreateDelete: data?.homeNewsCreateDelete ?? false,
    homeNewsEdit: data?.homeNewsEdit ?? false,
    homeReportsView: data?.homeReportsView ?? false,
    homeCalendarEdit: data?.homeCalendarEdit ?? false,

    locationsView: data?.locationsView ?? false,
    locationsCreateDelete: data?.locationsCreateDelete ?? false,
    locationsEdit: data?.locationsEdit ?? false,

    employeesView: data?.employeesView ?? false,
    employeesCreateDelete: data?.employeesCreateDelete ?? false,
    employeesEdit: data?.employeesEdit ?? false,

    trainersView: data?.trainersView ?? false,
    trainersCreateDelete: data?.trainersCreateDelete ?? false,
    trainersEdit: data?.trainersEdit ?? false,

    coursesView: data?.coursesView ?? false,
    coursesCreateDelete: data?.coursesCreateDelete ?? false,
    coursesEdit: data?.coursesEdit ?? false,
    dateTimesView: data?.dateTimesView ?? false,
    dateTimesEdit: data?.dateTimesEdit ?? false,
    appointmentsView: data?.appointmentsView ?? false,
    appointmentsCreateDelete: data?.appointmentsCreateDelete ?? false,
    appointmentsEdit: data?.appointmentsEdit ?? false,

    invoicesView: data?.invoicesView ?? false,
    invoicesEdit: data?.invoicesEdit ?? false,
    invoicePositionsView: data?.invoicePositionsView ?? false,
    invoicePositionsEdit: data?.invoicePositionsEdit ?? false,
    directDebitViewCreate: data?.directDebitViewCreate ?? false,

    attendeesView: data?.attendeesView ?? false,
    attendeesCreateDelete: data?.attendeesCreateDelete ?? false,
    attendeesEdit: data?.attendeesEdit ?? false,
    interestedView: data?.interestedView ?? false,
    interestedCreateDelete: data?.interestedCreateDelete ?? false,
    interestedEdit: data?.interestedEdit ?? false,

    statisticsViewCreate: data?.statisticsViewCreate ?? false,
    myCompanyViewEdit: data?.myCompanyViewEdit ?? false,
    companySubscriptionsViewEdit: data?.companySubscriptionsViewEdit ?? false,
    settingsViewEdit: data?.settingsViewEdit ?? false,

    referencesView: data?.referencesView ?? false,
    tariffsCreateDelete: data?.tariffsCreateDelete ?? false,
    tariffsEdit: data?.tariffsEdit ?? false,
    departmentsCreateDelete: data?.departmentsCreateDelete ?? false,
    departmentsEdit: data?.departmentsEdit ?? false,
    membershipTypesCreateDelete: data?.membershipTypesCreateDelete ?? false,
    membershipTypesEdit: data?.membershipTypesEdit ?? false,
    memberStatusesCreateDelete: data?.memberStatusesCreateDelete ?? false,
    memberStatusesEdit: data?.memberStatusesEdit ?? false,
    costsCreateDelete: data?.costsCreateDelete ?? false,
    costsEdit: data?.costsEdit ?? false,
    licencesCreateDelete: data?.licencesCreateDelete ?? false,
    licencesEdit: data?.licencesEdit ?? false,
    skillsCreateDelete: data?.skillsCreateDelete ?? false,
    skillsEdit: data?.skillsEdit ?? false,
    characteristicsCreateDelete: data?.characteristicsCreateDelete ?? false,
    characteristicsEdit: data?.characteristicsEdit ?? false,
    equipmentsCreateDelete: data?.equipmentsCreateDelete ?? false,
    equipmentsEdit: data?.equipmentsEdit ?? false,
    attendeeRolesEdit: data?.attendeeRolesEdit ?? false,
    attendeeRolesCreateDelete: data?.attendeeRolesCreateDelete ?? false,
  };
}

export function getDefaultPermissions(authorities: string[]): ApplicationUserPermissions {
  const adminOrManager = authorities.includes(AUTHORITIES.ADMIN) || authorities.includes(AUTHORITIES.MANAGER);
  const employee = authorities.includes(AUTHORITIES.EMPLOYEE);

  return {
    // Placeholder data
    id: CONST.emptyGuid,
    applicationUserId: CONST.emptyGuid,
    companyId: CONST.emptyGuid,

    homeConflictsViewEdit: adminOrManager || employee,
    homeTodoViewEdit: adminOrManager || employee,
    homeNewsView: true,
    homeNewsCreateDelete: adminOrManager || employee,
    homeNewsEdit: adminOrManager || employee,
    homeReportsView: adminOrManager || employee,
    homeCalendarEdit: true,

    locationsView: true,
    locationsCreateDelete: adminOrManager,
    locationsEdit: adminOrManager,

    employeesView: adminOrManager || employee,
    employeesCreateDelete: adminOrManager,
    employeesEdit: adminOrManager,

    trainersView: true,
    trainersCreateDelete: adminOrManager,
    trainersEdit: adminOrManager,

    coursesView: true,
    coursesCreateDelete: adminOrManager || employee,
    coursesEdit: adminOrManager || employee,
    dateTimesView: adminOrManager || employee,
    dateTimesEdit: adminOrManager || employee,
    appointmentsView: true,
    appointmentsCreateDelete: adminOrManager || employee,
    appointmentsEdit: adminOrManager || employee,

    invoicesView: adminOrManager || employee,
    invoicesEdit: adminOrManager || employee,
    invoicePositionsView: adminOrManager || employee,
    invoicePositionsEdit: adminOrManager || employee,
    directDebitViewCreate: adminOrManager,

    attendeesView: true,
    attendeesCreateDelete: adminOrManager || employee,
    attendeesEdit: adminOrManager || employee,
    interestedView: true,
    interestedCreateDelete: true,
    interestedEdit: true,

    statisticsViewCreate: adminOrManager,
    myCompanyViewEdit: adminOrManager,
    companySubscriptionsViewEdit: adminOrManager,
    settingsViewEdit: adminOrManager,

    referencesView: adminOrManager,
    tariffsCreateDelete: adminOrManager,
    tariffsEdit: adminOrManager,
    departmentsCreateDelete: adminOrManager,
    departmentsEdit: adminOrManager,
    membershipTypesCreateDelete: adminOrManager,
    membershipTypesEdit: adminOrManager,
    memberStatusesCreateDelete: adminOrManager,
    memberStatusesEdit: adminOrManager,
    costsCreateDelete: adminOrManager,
    costsEdit: adminOrManager,
    licencesCreateDelete: adminOrManager,
    licencesEdit: adminOrManager,
    skillsCreateDelete: adminOrManager,
    skillsEdit: adminOrManager,
    characteristicsCreateDelete: adminOrManager,
    characteristicsEdit: adminOrManager,
    equipmentsCreateDelete: adminOrManager,
    equipmentsEdit: adminOrManager,
    attendeeRolesCreateDelete: adminOrManager,
    attendeeRolesEdit: adminOrManager,
  };
}

export default {
  parse,
  toAPI: toAPI,
};