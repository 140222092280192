import { ActionTree } from 'vuex';
import { RootState } from '../../types';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { ApplicationUserPermissions } from '@/shared/model/applicationUserPermissions';
import { defaultBackendApplicationUserPermissions } from '@/shared/backend/applicationUserPermissions';
import { ApplicationUserPermissionsState } from './types';
import { i18nGlobal } from '@/i18n';

const logger = new Logger('actions.applicationUserPermissions');
export const actions: ActionTree<ApplicationUserPermissionsState, RootState> = {
  getPermissionsByUserId({ commit }, userId: string) {
    commit('setPermissionsIsLoading', true);
    commit('setCurrentUserId', userId);

    return defaultBackendApplicationUserPermissions
      .getApplicationUserPermissionsByUserId(userId)
      .then((res) => { commit('setCurrentPermissions', res.data); })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => { commit('setPermissionsIsLoading', false); });
  },
  updateUserPermissions({ commit }, permissions: ApplicationUserPermissions) {
    commit('setPermissionsIsLoading', true);

    return defaultBackendApplicationUserPermissions
      .updateApplicationUserPermissions(permissions)
      .then(() => {
        moduleUtils.ok(i18nGlobal.t(`success.permissions_update`), commit);
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => { commit('setPermissionsIsLoading', false); });
  }
}
