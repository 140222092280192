export interface DirectDebit {
  messageIdentification: string;
  creationDateTime: string;
  numberOfTransactions: number;
  controlSum: number;
  creditorName: string;
  creditorIBAN: string;
  creditorIdentifier: string;
  collectionDate: string;
  transactions: DirectDebitTransaction[];
}

export interface DirectDebitTransaction {
  endToEndIdentification: string;
  instructedAmount: number;
  mandateIdentification: string;
  mandateDate: string;
  debitorName: string;
  debitorIBAN: string;
  remittanceInformation: string;
  sequenceType: DirectDebitTransactionSequenceType
}

export enum DirectDebitTransactionSequenceType
{
    FRST = 0, // First
    RCUR = 1, // Recurrent
    OOFF = 2, // One off
    FNAL = 3, // Final
}

function parseTransaction(data?: Partial<DirectDebitTransaction>): DirectDebitTransaction {
  return {
    endToEndIdentification: data?.endToEndIdentification || '',
    instructedAmount: data?.instructedAmount || 0,
    mandateIdentification: data?.mandateIdentification || '',
    mandateDate: data?.mandateDate || '',
    debitorName: data?.debitorName || '',
    debitorIBAN: data?.debitorIBAN || '',
    remittanceInformation: data?.remittanceInformation || '',
    sequenceType: data?.sequenceType ?? DirectDebitTransactionSequenceType.RCUR,
  };
}

function parse(data?: Partial<DirectDebit>): DirectDebit {
  return {
    messageIdentification: data?.messageIdentification || '',
    creationDateTime: data?.creationDateTime || '',
    numberOfTransactions: data?.numberOfTransactions || 0,
    controlSum: data?.controlSum || 0,
    creditorName: data?.creditorName || '',
    creditorIBAN: data?.creditorIBAN || '',
    creditorIdentifier: data?.creditorIdentifier || '',
    collectionDate: data?.collectionDate || '',
    transactions: (data?.transactions || []).map(parseTransaction)
  };
}

function transactionToAPI(data?: Partial<DirectDebitTransaction>) {
  return {
    EndToEndIdentification: data?.endToEndIdentification || '',
    InstructedAmount: data?.instructedAmount || 0,
    MandateIdentification: data?.mandateIdentification || '',
    MandateDate: data?.mandateDate || '',
    DebitorName: data?.debitorName || '',
    DebitorIBAN: data?.debitorIBAN || '',
    RemittanceInformation: data?.remittanceInformation || '',
    SequenceType: data?.sequenceType ?? DirectDebitTransactionSequenceType.RCUR
  };
}

function toAPI(data?: Partial<DirectDebit>) {
  return {
    MessageIdentification: data?.messageIdentification || '',
    NumberOfTransactions: data?.numberOfTransactions || 0,
    ControlSum: data?.controlSum || 0,
    CreditorName: data?.creditorName || '',
    CreditorIBAN: data?.creditorIBAN || '',
    CreditorIdentifier: data?.creditorIdentifier || '',
    CollectionDate: data?.collectionDate || new Date().toISOString(),
    Transactions: (data?.transactions || []).map(transactionToAPI)
  };
}

export default {
  parseTransaction,
  parse,
  toAPI
};
