import { ActionTree } from 'vuex';
import { AttendeeState } from './types';
import { RootState } from '../../types';
import { defaultBackendAttendee } from '@/shared/backend/attendee';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import attendee, { Value, Attendee } from '@/shared/model/attendee';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { ApplicationUser } from '@/shared/model/applicationUser';
import { isEmptyId } from '@/shared/utils/generalUtils';

const logger = new Logger('actions.attendees');
export const actions: ActionTree<AttendeeState, RootState> = {
  getAttendees(
    { commit, dispatch, getters, rootGetters },
    payload?: {
      searchParams?: any;
      locationId?: string;
      departmentId?: string;
      noStore?: boolean;
      active?: boolean;
      excludeAttendeeIds?: string[];
      attendeeIds?: string[];
    }
  ) {
    commit("setAttendeesIsLoading", true);
    let searchParams =
      payload?.searchParams ?? getters.getAttendeesSearchParams;
    return defaultBackendAttendee
      .getAttendees(
        searchParams,
        payload?.locationId,
        payload?.departmentId,
        payload?.active,
        payload?.excludeAttendeeIds,
        payload?.attendeeIds
      )
      .then((response: AxiosResponse<Value>) => {
        commit("setAttendeesIsLoading", false);
        if (payload?.noStore) return response.data;

        commit("setAttendees", response.data);
        return response.data;
      })
      .catch((e: any) => {
        commit("setAttendeesIsLoading", false);
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },

  getInterested(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; locationId?: string; departmentId?: string }
  ) {
    commit("setInterestedIsLoading", true);
    let searchParams =
      payload?.searchParams ?? getters.getInterestedSearchParams;
    return defaultBackendAttendee
      .getInterested(searchParams, payload?.locationId, payload?.departmentId)
      .then((response: AxiosResponse<Value>) => {
        commit("setInterested", response.data);
        commit("setInterestedIsLoading", false);
        return response.data;
      })
      .catch((e: any) => {
        commit("setInterestedIsLoading", false);
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },

  getAttendeeWaitingList(
    { commit, getters },
    payload?: { searchParams?: any; appointmentId?: string }
  ) {
    commit("setWaitingListIsLoading", true);
    const appointmentId = payload?.appointmentId ?? "";
    if (isEmptyId(appointmentId)) {
      return;
    }
    const searchParams =
      payload?.searchParams ?? getters.getInterestedSearchParams;
    return defaultBackendAttendee
      .getAttendeeWaitingList(searchParams, appointmentId)
      .then((response: AxiosResponse<Value>) => {
        commit("setWaitingList", response.data);
        commit("setWaitingListIsLoading", false);
        return response.data;
      })
      .catch((e: any) => {
        commit("setWaitingListIsLoading", false);
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },

  getAttendeesByTrainerId(
    { commit, dispatch, getters, rootGetters },
    payload: {
      searchParams?: any;
      trainerId: string;
      locationId?: string;
      departmentId?: string;
      noStore?: string;
    }
  ) {
    commit("setAttendeesIsLoading", true);
    let searchParams =
      payload?.searchParams ?? getters.getAttendeesSearchParams;
    return defaultBackendAttendee
      .getAttendeesByTrainerId(
        searchParams,
        payload.trainerId,
        payload?.locationId,
        payload?.departmentId
      )
      .then((response: AxiosResponse<Value>) => {
        commit("setAttendeesIsLoading", false);
        if (payload?.noStore) return response.data;

        commit("setAttendees", response.data);
        return response.data;
      })
      .catch((e: any) => {
        commit("setAttendeesIsLoading", false);
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },

  getAttendee({ commit, dispatch }, id: string) {
    return defaultBackendAttendee
      .getAttendee(id)
      .then((response: AxiosResponse<Attendee>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },

  getAttendeeByAttendeeId({ commit }, attendeeId: string) {
    return defaultBackendAttendee
      .getAttendeeByAttendeeId(attendeeId)
      .then((response: AxiosResponse<Attendee>) => {
        return attendee.parse(response.data);
      })
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },

  requestNewMemberNumber({ commit }) {
    return defaultBackendAttendee
      .requestNewMemberNumber()
      .then((response: AxiosResponse<string>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },

  updateAttendee(
    { commit },
    payload: { attendee: Attendee; sendMembershipTerminatedMessages?: boolean }
  ) {
    return defaultBackendAttendee
      .updateAttendee(payload.attendee, payload.sendMembershipTerminatedMessages)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendee;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.attendee_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  updateInterestedAttendee({ commit, dispatch }, attendee: Attendee) {
    return defaultBackendAttendee
      .updateInterestedAttendee(attendee)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendee;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.attendee_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  registerAttendee(
    { commit, dispatch },
    payload: { newAttendee: ApplicationUser; locationId: string }
  ) {
    return defaultBackendAttendee
      .registerAttendee(payload.newAttendee, payload.locationId)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendee;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.register_attendee`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  registerInterestedAttendeeByEmployee(
    { commit, dispatch },
    payload: { newAttendee: Attendee; locationId: string }
  ) {
    return defaultBackendAttendee
      .registerInterestedAttendeeByEmployee(
        payload.newAttendee,
        payload.locationId
      )
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendee;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.register_attendee`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  deleteAttendee({ commit, dispatch }, id: string) {
    return defaultBackendAttendee
      .deleteAttendee(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendee;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  deleteInterestedAttendee({ commit, dispatch }, id: string) {
    return defaultBackendAttendee
      .deleteInterestedAttendee(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendee;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
};
