import { ActionTree } from "vuex";
import { PresenceState } from "./types";
import { RootState } from "../../types";
import { defaultBackendPresence } from "@/shared/backend/presence";
import { Logger } from "fsts";
import moduleUtils from "../moduleUtils";
import { Value, Presence } from "@/shared/model/presence";
import { AxiosResponse } from "axios";
import { i18nGlobal } from "@/i18n";
import { AUTHORITIES } from "../auth";

const logger = new Logger("actions.presences");
export const actions: ActionTree<PresenceState, RootState> = {
  getPresences(
    { commit, getters, rootGetters },
    payload?: {
      searchParams?: any;
      roles?: AUTHORITIES[];
      currentDateTime?: string;
      locationId?: string;
      applicationUserId?: string;
      isOnline?: boolean;
      trainerId?: string;
      fromTillDate?: { fromDate: string|undefined, tillDate: string|undefined };
    }
  ) {
    commit("setPresencesIsLoading", true);
    let searchParams =
      payload?.searchParams ?? getters.getPresencesSearchParams;
    return defaultBackendPresence
      .getPresences(
        searchParams,
        payload?.roles,
        payload?.currentDateTime,
        payload?.locationId,
        payload?.applicationUserId,
        payload?.isOnline,
        payload?.trainerId,
        payload?.fromTillDate,
      )
      .then((response: AxiosResponse<Value>) => {
        commit("setPresences", response.data);
        commit("setPresencesIsLoading", false);
        return response.data;
      })
      .catch((e: any) => {
        commit("setPresencesIsLoading", false);
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  getPresence({ commit }, id: string) {
    return defaultBackendPresence
      .getPresence(id)
      .then((response: AxiosResponse<Presence>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  updatePresence({ commit }, file: Presence) {
    return defaultBackendPresence
      .updatePresence(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Presence;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.presence_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  deletePresence({ commit }, id: string) {
    return defaultBackendPresence
      .deletePresence(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Presence;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  enterPresence(
    { commit },
    payload: {
      applicationUserId?: string;
      attendeeId?: string;
      locationId: string;
      text?: string;
    }
  ) {
    if (
      (!payload.applicationUserId && !payload.attendeeId) ||
      !payload.locationId
    ) {
      return;
    }
    return defaultBackendPresence
      .enterPresence(payload)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Presence;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.presence_enter`), commit);
          return response.data.id;
        }
      )
      .catch((e: any) => {
        moduleUtils.ok(i18nGlobal.t(`error.presence_enter`), commit);
        throw e;
      });
  },

  leavePresence(
    { commit },
    payload: { applicationUserId?: string; attendeeId?: string }
  ) {
    if (!payload.applicationUserId && !payload.attendeeId) {
      return;
    }
    return defaultBackendPresence
      .leavePresence(payload)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Presence;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.presence_leave`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.ok(i18nGlobal.t(`error.presence_leave`), commit);
        throw e;
      });
  },
  lastState(
    { commit },
    payload: { applicationUserId?: string; attendeeId?: string }
  ) {
    if (!payload.applicationUserId && !payload.attendeeId) {
      return;
    }
    return defaultBackendPresence
      .lastState(payload)
      .then((response: any) => {
        return response.data.result;
      })
      .catch((e: any) => {
        throw e;
      });
  },
  countOnline(
    {},
    payload: {
      roles?: AUTHORITIES[];
      locationId?: string;
      trainerId?: string;
      applicationUserId?: string;
    }
  ) {
    return defaultBackendPresence
      .countOnline(
        payload.roles,
        payload.locationId,
        payload.trainerId,
        payload.applicationUserId
      )
      .then((res) => res.data)
      .catch((e: any) => {
        throw e;
      });
  },
};
