import { AxiosPromise } from "axios";
import { instance } from ".";
import { URLS } from "./index";
import applicationUserPermissionsFuncs, {
  ApplicationUserPermissions,
} from "../model/applicationUserPermissions";
import { isEmptyId } from "../utils/generalUtils";

export interface BackendApplicationUserPermissions {
  getApplicationUserPermissionsByUserId(
    userId: string
  ): AxiosPromise<ApplicationUserPermissions>;
  updateApplicationUserPermissions(
    applicationUserPermissions: ApplicationUserPermissions
  ): AxiosPromise<any>;
}

export const defaultBackendApplicationUserPermissions: BackendApplicationUserPermissions = {
  getApplicationUserPermissionsByUserId(
    userId: string
  ): AxiosPromise<ApplicationUserPermissions> {
    const url = `${URLS.applicationUserPermissions}/GetByUserId/${userId}`;
    return instance.get<ApplicationUserPermissions>(url);
  },
  updateApplicationUserPermissions(
    applicationUserPermissions: ApplicationUserPermissions
  ): AxiosPromise<any> {
    if (isEmptyId(applicationUserPermissions.id)) {
      return instance.post<any>(
        URLS.applicationUserPermissions,
        applicationUserPermissionsFuncs.toAPI(applicationUserPermissions)
      );
    } else {
      return instance.put<any>(
        `${URLS.applicationUserPermissions}/${applicationUserPermissions.id}`,
        applicationUserPermissionsFuncs.toAPI(applicationUserPermissions)
      );
    }
  },
};
