import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import appointmentTrainer, {
  AppointmentTrainer,
  Value,
} from '../model/appointmentTrainer';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('backend.AppointmentTrainer');
export interface BackendAppointmentTrainer {
  getAppointmentTrainer(id: string): AxiosPromise<AppointmentTrainer>;
  getAppointmentTrainers: (
    searchParams: SearchParams,
    appointmentId: string
  ) => AxiosPromise<Value>;
  deleteAppointmentTrainer(id: string): AxiosPromise;
  updateAppointmentTrainer(
    AppointmentTrainer: AppointmentTrainer
  ): AxiosPromise<any>;
  addMultipleAppointmentTrainers(
    appointmentId: string,
    trainersId: string[]
  ): AxiosPromise<any>;
  addAppointmentTrainersByDateTimeId(
    dateTimeId: string,
    doNotChangeExistingAppointments: boolean
  ): AxiosPromise<any>;
  export(
    fromDate: string,
    tillDate: string,
    trainerId?: string,
    trainerIds?: string[],
    locationIds?: string[],
    departmentIds?: string[],
    culture?: string
  ): AxiosPromise<any>;
}

export const defaultBackendAppointmentTrainer: BackendAppointmentTrainer = {
  getAppointmentTrainer(id: string): AxiosPromise<AppointmentTrainer> {
    let url = `${URLS.appointmentTrainerOdata}/${id}`;
    return instance.get<AppointmentTrainer>(url);
  },
  getAppointmentTrainers(
    searchParams: SearchParams,
    appointmentId: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.AppointmentId, appointmentId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.appointmentTrainerOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAppointmentTrainers${url}`);
    return instance.get<Value>(url);
  },

  deleteAppointmentTrainer(id: string): AxiosPromise {
    logger.debug('deleteAppointmentTrainer');
    return instance.delete(`${URLS.appointmentTrainer}/${id}`);
  },
  updateAppointmentTrainer(
    AppointmentTrainer: AppointmentTrainer
  ): AxiosPromise<any> {
    logger.debug('updateAppointmentTrainer');
    return instance.put<AppointmentTrainer>(
      `${URLS.appointmentTrainer}/update`,
      appointmentTrainer.toAPI(AppointmentTrainer)
    );
  },
  addAppointmentTrainersByDateTimeId(
    dateTimeId: string,
    doNotChangeExistingAppointments: boolean
  ): AxiosPromise<any> {
    logger.debug('addAppointmentTrainersByDateTimeId');
    return instance.post<AppointmentTrainer>(
      `${URLS.appointmentTrainer}/AddByDateTimeId`,
      {
        DateTimeId: dateTimeId,
        DoNotChangeExistingAppointments: doNotChangeExistingAppointments,
      }
    );
  },
  addMultipleAppointmentTrainers(
    appointmentId: string,
    trainersId: string[]
  ): AxiosPromise<any> {
    logger.debug('addMultipleAppointmentTrainers');
    return instance.post<AppointmentTrainer>(
      `${URLS.appointmentTrainer}/AddMultiple/${appointmentId}`,
      {
        trainersId: trainersId,
      }
    );
  },
  export(
    fromDate: string,
    tillDate: string,
    trainerId?: string,
    trainerIds?: string[],
    locationIds?: string[],
    departmentIds?: string[],
    culture?: string
  ): AxiosPromise<any> {
    let url = `${URLS.appointmentTrainer}/Export`;
    if (culture) url = DefaultBackendHelper.addParamsToUrl(url, [`culture=${culture}`]);

    return instance.post(
      url,
      {
        FromDate: fromDate,
        TillDate: tillDate,
        TrainerId: trainerId || null,
        TrainerIds: trainerIds,
        LocationIds: locationIds,
        DepartmentIds: departmentIds,
      },
      { responseType: 'blob' }
    );
  }
};
